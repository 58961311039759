<template>
  <div class="extension va-row">
    <div class="flex md12 xs12">
      <div class="d-flex align--center datatable-controls bg-white p-3">
        <items-per-page
          :options="perPageOptions"
          :label="$t('extensions.per-page')"
          :defaultPerPage="perPage"
          @items-per-page="onPerPageChange"
        />
        <button
          class="btn rounded btn-micro btn-primary mx-2"
          @click="openFilterMenu()"
        >
          {{ $t('extensions.filter-by') }}
        </button>
        <filter-bar
          class="mx-auto"
          @input="onSearchChange"
          :label="$t('extensions.search')"
        />
        <div class="d-flex additionalTableControl">
          <button
            @click="
              if (currentTenant.isDemo) {
                demoCallSupportModalOpen = true
              } else if (currentUser.role != `Admin`) {
                actionDeniedModalOpen = true
              } else {
                $router.push({ name: 'extension-create' })
              }
            "
            class="btn btn-micro btn-primary rounded"
          >
            <i class="ion-md-add ion"></i>
            {{ $t('buttons.create') }}
          </button>
        </div>
      </div>
      <data-table
        ref="extensionsTable"
        :fields="translatedTableFields"
        :data="extensions"
        :pagination="extensionsPagination"
        :isLoading="isExtensionsLoading"
        @pageChange="onPageChange"
      >
        <template slot="actions" slot-scope="props">
          <div
            class="table-button-container d-flex justify-content-center align-items-center"
            v-if="props.rowData.status !== 'pending'"
          >
            <img
              :src="require('@/assets/images/icon-edit.svg')"
              class="datatable-btns"
              :alt="$t('buttons.edit')"
              :title="$t('buttons.edit')"
              @click="editExtension(props.rowData)"
            />
            <button class="bg-transparent border-0">
              <i
                class="h3 m-0 fa fa-trash text-danger"
                @click="
                  if (currentTenant.isDemo) {
                    demoCallSupportModalOpen = true
                  } else if (currentUser.role != `Admin`) {
                    actionDeniedModalOpen = true
                  } else {
                    deleteExtensionModalOpen = true
                    currentExtension = props.rowData
                  }
                "
              ></i>
            </button>
            <img
              :src="
                props.rowData.status === 'enabled'
                  ? require('@/assets/images/icon-deactivate_extension.svg')
                  : require('@/assets/images/icon-activate_extension.svg')
              "
              class="datatable-btns"
              :alt="
                props.rowData.status === 'enabled' ? 'Deactivate' : 'Activate'
              "
              :title="
                props.rowData.status === 'enabled' ? 'Deactivate' : 'Activate'
              "
              @click="
                if (currentTenant.isDemo) {
                  demoCallSupportModalOpen = true
                } else if (currentUser.role != `Admin`) {
                  actionDeniedModalOpen = true
                } else {
                  openPopup(props.rowData)
                }
              "
            />
          </div>
        </template>
      </data-table>
    </div>

    <app-filterbar>
      <template slot="filterbar-content">
        <div class="filterbar-input">
          <p class="filter-selector">
            {{ $t('extensions.filters.extension-number') }}
          </p>
          <vuestic-multi-select
            v-model="filter.extensions"
            :options="extensionsNumbers"
            :optionKey="'ext'"
            :pholder="$t('extensions.filters.extension-number-placeholder')"
          />
        </div>
        <div class="filterbar-input">
          <p class="filter-selector">{{ $t('extensions.filters.status') }}:</p>
          <label for="active_status">
            <input
              type="checkbox"
              id="active_status"
              v-model="filter.status.active"
            />
            {{ $t('extensions.filters.statuses.active') }}
          </label>
          <label for="inactive_status">
            <input
              type="checkbox"
              id="inactive_status"
              v-model="filter.status.inactive"
            />
            {{ $t('extensions.filters.statuses.inactive') }}
          </label>
        </div>
        <div class="w-100 text-right my-4">
          <span @click="resetFilters" class="filterbar-reset">
            {{ $t('shared.filterBar.reset-filters') }}
          </span>
        </div>
        <div class="w-100 text-center">
          <button
            class="btn btn-primary btn-sm filterbar-button"
            @click="applyFilters"
          >
            {{ $t('shared.filterBar.apply-filters') }}
          </button>
        </div>
      </template>
    </app-filterbar>
    <modal :isOpen="toggleStatusModalOpen" @dismiss="dismissPopup">
      <!-- <div v-if="extensionActive"> -->
      <div class="">
        <div v-if="currentExtension.status === 'enabled'">
          <h5 class="popup-title">
            {{ $t('extensions.activation-modal.deactivate.title') }}
          </h5>
          <span>
            {{ $t('extensions.activation-modal.deactivate.description') }}
          </span>
        </div>
        <div v-else>
          <h5 class="popup-title">
            {{ $t('extensions.activation-modal.reactivate.title') }}
          </h5>
          <span>
            {{ $t('extensions.activation-modal.reactivate.description') }}
          </span>
        </div>
        <div
          class="va-row w-100 justify--center mt-4"
          v-if="isToggleStatusLoading"
        >
          <spring-spinner
            slot="loading"
            :animation-duration="2500"
            :size="50"
            color="#008ecf"
          />
        </div>
        <div v-else>
          <div class="va-row w-100 justify--center mt-4">
            <div class="flex md5 xs12">
              <button
                type="button"
                class="btn btn-primary btn-micro btn-block"
                @click="toggleStatus"
              >
                {{ $t('extensions.activation-modal.yes') }}
              </button>
            </div>
          </div>
          <div class="va-row w-100 justify--center">
            <div class="flex md5 xs12">
              <button
                type="button"
                class="btn btn-danger btn-micro btn-block"
                @click="dismissPopup"
              >
                {{ $t('extensions.activation-modal.no') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      :isOpen="deleteExtensionModalOpen"
      @dismiss="deleteExtensionModalOpen = false"
    >
      <div class="">
        <div>
          <h5 class="popup-title">
            {{ $t('extensions.delete-modal.title') }}
          </h5>
          <span>
            {{ $t('extensions.delete-modal.description') }}
          </span>
        </div>

        <div
          class="va-row w-100 justify--center mt-4"
          v-if="isDeleteExtensionLoading"
        >
          <spring-spinner
            slot="loading"
            :animation-duration="2500"
            :size="50"
            color="#008ecf"
          />
        </div>
        <div v-else>
          <div class="va-row w-100 justify--center mt-4">
            <div class="flex md5 xs12">
              <button
                type="button"
                class="btn btn-primary btn-micro btn-block"
                @click="deleteExtension(currentExtension)"
              >
                {{ $t('extensions.delete-modal.yes') }}
              </button>
            </div>
          </div>
          <div class="va-row w-100 justify--center">
            <div class="flex md5 xs12">
              <button
                type="button"
                class="btn btn-danger btn-micro btn-block"
                @click="deleteExtensionModalOpen = false"
              >
                {{ $t('extensions.delete-modal.no') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      :modalContentStyle="{ width: '300px' }"
      :isOpen="demoCallSupportModalOpen"
      @dismiss="demoCallSupportModalOpen = false"
      inno
    >
      <DemoCallSupportModal />
    </modal>

    <modal
      :modalContentStyle="{ width: '300px' }"
      :isOpen="actionDeniedModalOpen"
      @dismiss="actionDeniedModalOpen = false"
      inno
    >
      <ActionDeniedModal />
    </modal>

    <!-- actionDeniedModalOpen -->
  </div>
</template>

<script>
import Vue from 'vue'
import FilterBar from '../../vuestic-theme/vuestic-components/vuestic-datatable/datatable-components/FilterBar'
import ItemsPerPage from '../../vuestic-theme/vuestic-components/vuestic-datatable/datatable-components/ItemsPerPage'
import ExtensionStatus from './components/extension-status'
Vue.component('extension-status', ExtensionStatus)
import DataTable from '../../elements/DataTable'
import { mapGetters, mapActions } from 'vuex'
import http from '../../axios'
import Modal from '../../elements/Modal'
import SpringSpinner from 'epic-spinners/src/components/lib/SpringSpinner'
import AppFilterbar from '@/components/admin/app-filterbar/AppFilterbar'
import ExtensionService from '../../services/extension.service'
import momentTimezone from 'moment-timezone'
import DemoCallSupportModal from '../../elements/DemoCallSupportModal.vue'
import ActionDeniedModal from '../../elements/ActionDeniedModal.vue'

export default {
  components: {
    DataTable,
    Modal,
    FilterBar,
    AppFilterbar,
    ItemsPerPage,
    SpringSpinner,
    DemoCallSupportModal,
    ActionDeniedModal
  },
  data() {
    return {
      httpOptions: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'ngrok-skip-browser-warning': true,
          timezone: momentTimezone.tz.guess()
        }
      },
      filter: {
        extensions: [],
        status: {
          active: true,
          inactive: true
        }
      },
      formLoading: false,
      model: {
        phone: ''
      },
      error: '',
      tableFields: [
        {
          name: 'name',
          title: 'extensions.table.name'
        },
        {
          name: 'ext',
          title: 'extensions.table.extension-number'
        },
        {
          name: 'email',
          title: 'extensions.table.email'
        },
        {
          name: '__component:extension-status',
          title: 'extensions.table.status'
        },
        {
          name: '__slot:actions',
          title: 'extensions.table.Actions'
        }
      ],
      data: [],
      extensions: [],
      extensionsNumbers: [],
      extensionsPagination: {
        perPage: 10
      },
      isExtensionsLoading: false,
      currentExtension: {},
      toggleStatusModalOpen: false,
      isToggleStatusLoading: false,
      perPageOptions: [
        {
          value: 10
        },
        {
          value: 25
        },
        {
          value: 100
        }
      ],
      perPage: 10,
      currentPage: 1,
      pendingRefreshInterval: null,
      deleteExtensionModalOpen: false,
      isDeleteExtensionLoading: false,
      demoCallSupportModalOpen: false,
      actionDeniedModalOpen: false
    }
  },
  computed: {
    ...mapGetters([
      'currentTenant',
      'allExtensions',
      'pbxSubscription',
      'currentUser'
    ]),
    apiUrl() {
      return `${process.env.VUE_APP_NEW_API}extension`
    },
    extensionsNumbersApiUrl() {
      return `${process.env.VUE_APP_NEW_API}extension/list`
    },
    translatedTableFields() {
      return this.tableFields.map(field => {
        return {
          ...field,
          title: this.$t(field.title)
        }
      })
    },
    locale() {
      return Vue.i18n.locale()
    }
  },
  watch: {
    currentTenant() {
      if (this.currentTenant) {
        this.getExtensions()
        this.getExtensionsNumbers()
      }
    },
    locale() {
      if (this.$refs.extensionsTable) {
        setTimeout(() => {
          this.$refs.extensionsTable.refresh()
          this.$refs.extensionsTable.normalizeFields()
        }, 0)
      }
    }
  },
  mounted() {
    this.getExtensions()
    this.getExtensionsNumbers()
  },
  methods: {
    ...mapActions(['toggleExtensionStatus', 'openTicket', 'setExtensions']),
    async requestCall() {
      this.formLoading = true
      this.model.departmentId = `${process.env.VUE_APP_SUPPORT_DEPARTMENT_ID}`
      this.model.subject = 'Subscription upgrade enquiry'
      this.model.description = `This is an automatically created ticket for the user to receive  details about :
  1. Creating and managing extensions
  2. Dashboard options and charts
  3. Details about  call reports  `

      const response = await this.openTicket(this.model)

      if (response.success == true) {
        this.toast(response.data.message)
        this.model = {}
        this.error = ''
        this.$store.dispatch('togglePopup', false)
      } else {
        this.error = response.errors.phone[0]
      }

      this.formLoading = false
    },
    openSubscriptionPopup() {
      this.$store.dispatch('togglePopup', true)
    },
    async openFilterMenu() {
      if (this.allExtensions.length === 0) {
        await this.$store.dispatch('fetchAllExtensions')
      }
      this.$store.dispatch('toggleFilterbar', true)
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    applyFilters() {
      const { extensions, status } = this.filter

      let filteredExtensions = this.data.filter(ext => {
        // extensions Filter
        let extensionsFilter = false
        // check if the current extension exists in the filter
        if (extensions.length)
          extensionsFilter = extensions.some(e => e.id === ext.id)
        else extensionsFilter = true

        // status Filter
        let statusFilter = false
        // check if the current extension status is active
        if (!status.active && !status.inactive) statusFilter = true
        else if (status.active && ext.status === 'enabled') statusFilter = true
        else if (status.inactive && ext.status === 'disabled')
          statusFilter = true
        else statusFilter = false

        return extensionsFilter && statusFilter
      })
      this.extensions = filteredExtensions
    },
    resetFilters() {
      this.filter = {
        extensions: [],
        status: {
          active: true,
          inactive: true
        }
      }
      this.applyFilters()
    },
    async getExtensions() {
      try {
        this.isExtensionsLoading = true
        const res = await http.get(this.apiUrl, this.httpOptions)
        this.extensions = res.data

        this.setExtensions(res.data)
        this.data = res.data
        if (!!res.data.length) this.currentExtension = res.data[0]

        const hasPending = res.data.some(
          extension => extension.status === 'pending'
        )

        if (hasPending && this.currentPage === 1) {
          this.pendingRefreshInterval = setInterval(async () => {
            const res = await http.get(this.apiUrl, this.httpOptions)
            this.extensions = res.data
            this.setExtensions(res.data)
            this.data = res.data
            this.currentExtension = this.data[0]

            const hasPending = res.data.some(
              extension => extension.status === 'pending'
            )

            if (!hasPending) {
              clearInterval(this.pendingRefreshInterval)
            }
          }, 2000)
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isExtensionsLoading = false
      }
    },
    async getExtensionsNumbers() {
      const res = await http.get(this.extensionsNumbersApiUrl, this.httpOptions)
      this.extensionsNumbers = res.data
    },
    async editExtension(ext) {
      this.$router.push({ path: `/extensions/update/${ext.id}` })
    },
    async deleteExtension(ext) {
      try {
        this.isDeleteExtensionLoading = true
        await ExtensionService.deleteExtension(ext.id)
        this.getExtensions()
        this.toast(this.$t('extensions.toasts.deleted-successfully'))
      } catch (error) {
        this.toast(error.response.data.message, { type: 'error' })
      } finally {
        this.deleteExtensionModalOpen = false
        this.isDeleteExtensionLoading = false
      }
    },
    openPopup(ext) {
      this.toggleStatusModalOpen = true
      this.currentExtension = ext
    },
    onPerPageChange(val) {
      this.extensionsPagination.perPage = val
      Vue.nextTick(() => this.$refs.extensionsTable.refresh())
    },
    async toggleStatus() {
      this.isToggleStatusLoading = true
      const response = await this.toggleExtensionStatus(this.currentExtension)
      if (response.success) {
        this.dismissPopup()
        this.toast(response.data.message)
      }
      this.$store.dispatch('togglePopup', false)
      this.getExtensions()
      this.isToggleStatusLoading = false
    },
    dismissPopup() {
      this.toggleStatusModalOpen = false
    },
    onSearchChange(query) {
      this.extensions = this.data.filter(ext => {
        const rowText = [ext.name, ext.ext, ext.email].join('|').toLowerCase()
        return rowText.includes(query.toLowerCase())
      })
    },
    onPageChange(page) {
      clearInterval(this.pendingRefreshInterval)

      const perPage = this.extensionsPagination.perPage

      const extensionsChunk = this.extensions.slice(
        perPage * (page - 1),
        perPage * (page - 1) + perPage
      )

      const hasPending = extensionsChunk.some(
        extension => extension.status === 'pending'
      )

      if (hasPending) {
        this.pendingRefreshInterval = setInterval(async () => {
          const res = await http.get(this.apiUrl, this.httpOptions)
          const extensionsChunk = res.data.slice(
            perPage * (page - 1),
            perPage * (page - 1) + perPage
          )

          const hasPending = extensionsChunk.some(
            extension => extension.status === 'pending'
          )

          if (!hasPending) {
            this.extensions = res.data
            clearInterval(this.pendingRefreshInterval)
          }
        }, 2000)
      }
    }
  }
}
</script>
<style lang="scss">
.popup-content {
  padding: 0 !important;
}

.extension {
  .dropdown-menu-content {
    height: 300px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: #0d4d80;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fff;
      outline: 0;
    }
  }
}
.datatable-controls {
  background-color: $white;
  border-radius: 9px;
  padding: 15px 20px;
  margin-bottom: 30px;
  box-shadow: $widget-box-shadow;
  flex-wrap: wrap;
  .additionalTableControl {
    margin-left: auto;
  }
}
</style>
