<template>
  <span
    class="badge"
    :class="rowData.status === 'enabled' ? 'badge-primary' : 'badge-warning'"
  >
    {{ rowData.status }}
  </span>
</template>

<script>
export default {
  name: 'ExtensionStatus',
  props: {
    rowData: {
      type: Object,
      required: true
    }
  }
}
</script>
